import {
  React,
  loadable,
  Route,
  Switch,
  BrowserRouter as Router
} from './libraries'

import './App.css'
import { ScrollToTop } from './utils'

const App = () => {
  const Home = loadable(() => import('./pages/home'))
  const Article = loadable(() => import('./pages/article'))
  const DetailArticle = loadable(() => import('./pages/article/detail'))
  const Event = loadable(() => import('./pages/event'))
  const DetailEvent = loadable(() => import('./pages/event/detail'))
  const Info = loadable(() => import('./pages/info'))
  const DetailInfo = loadable(() => import('./pages/info/detail'))
  const About = loadable(() => import('./pages/about'))
  const Merchandised = loadable(() => import('./pages/merchandise'))
  const Gallery = loadable(() => import('./pages/gallery'))
  const Registration = loadable(() => import('./pages/register'))
  const Agenda = loadable(() => import('./pages/agenda'))
  const JoinEvent = loadable(() => import('./pages/join-event'))

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/article' exact component={Article} />
        <Route path='/detail-article/:id' exact component={DetailArticle} />
        <Route path='/event' exact component={Event} />
        <Route path='/detail-event/:id' exact component={DetailEvent} />
        <Route path='/info' exact component={Info} />
        <Route path='/detail-info/:id' exact component={DetailInfo} />
        <Route path='/merchandise' exact component={Merchandised} />
        <Route path='/gallery' exact component={Gallery} />
        <Route path='/about' exact component={About} />
        <Route path='/registration' exact component={Registration} />
        <Route path='/agenda' exact component={Agenda} />
        <Route path='/join-event' exact component={JoinEvent} />
        
      </Switch>
    </Router>
  )
}

export default App
